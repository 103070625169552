import { Link } from "gatsby";
import React, { ReactNode } from "react";
import { Route } from "../../../../lib/routes";

export interface TopHeaderNavItemProps extends Route {
  className?: string;
  icon: ReactNode;
  external?: boolean;
}

const Inside = ({ icon, label }: TopHeaderNavItemProps) => {
  return (
    <div className="flex space-x-2 items-center opacity-100 hover:opacity-80 duration-200 transition-all">
      <div className="icon text-primary">{icon}</div>
      <div className="label text-white">{label}</div>
    </div>
  );
};

export const TopHeaderNavItem = (props: TopHeaderNavItemProps) => {
  const { className = "", icon, to, label, external } = props;

  if (external) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer">
        <Inside {...props} />
      </a>
    );
  }

  return (
    <Link to={to} className={`${className}`}>
      <Inside {...props} />
    </Link>
  );
};
