import PropTypes from "prop-types";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import "./global.css";

const queryClient = new QueryClient();

const Layout = ({ children, className = "" }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className={className}>
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </QueryClientProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
