import React from "react";
import { BiEuro, BiLink } from "react-icons/bi";
import { FiPhoneCall } from "react-icons/fi";
import { RiTeamLine } from "react-icons/ri";
import { useContactInfo } from "../../../../lib/hooks/useContactInfo";
import { routes } from "../../../../lib/routes";
import { TopHeaderNavItem, TopHeaderNavItemProps } from "./TopHeaderNavItem";

export interface TopHeaderProps {
  className?: string;
}

export const TopHeader = (props: TopHeaderProps) => {
  const { className = "" } = props;
  const { phone, email, address } = useContactInfo();

  const topHeaderRoutes: TopHeaderNavItemProps[] = [
    {
      to: `tel:${phone}`,
      label: phone,
      icon: <FiPhoneCall />,
      external: true,
    },
    {
      ...routes.iWantToBecomeAnAgent,
      icon: <RiTeamLine />,
    },
    {
      ...routes.iWantToBecomeATipper,
      icon: <BiEuro className="text-xl" />,
    },
    {
      ...routes.instagramLinks,
      icon: <BiLink />,
    },
  ];

  return (
    <section
      className={`${className} bg-secondary flex justify-center py-3 space-x-6`}
    >
      {topHeaderRoutes.map((route) => (
        <TopHeaderNavItem {...route} key={route.to} />
      ))}
    </section>
  );
};
