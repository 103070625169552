import React, { ReactNode } from "react";

export interface FooterSectionHeaderProps {
  className?: string;
  children: ReactNode;
}

export const FooterSectionHeader = (props: FooterSectionHeaderProps) => {
  const { className = "", children } = props;
  return <h3 className="text-xl text-white opacity-95 mb-3">{children}</h3>;
};
