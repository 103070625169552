import { Box } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import HamburgerMenu from "react-hamburger-menu";
import { MobileMenuOverlay } from "./MobileMenuOverlay";

export type MobileMenuProps = React.HTMLAttributes<HTMLElement>;

function preventDefault(e) {
  e.preventDefault();
}

function disableScroll() {
  document.body.addEventListener("touchmove", preventDefault, {
    passive: false,
  });
}
function enableScroll() {
  document.body.removeEventListener("touchmove", preventDefault);
}

export const MobileMenu: React.FC<MobileMenuProps> = (
  props: MobileMenuProps
) => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (mobileMenuIsOpen) {
        document.body.style.height = "100vh";
        document.body.style.overflowY = "hidden";
        // disableScroll()
      } else {
        document.body.style.height = "";
        document.body.style.overflowY = "initial";
        enableScroll();
      }
    }, 230);
  }, [mobileMenuIsOpen]);

  const handleChangeMenuOpenedClicked = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };

  return (
    <div className={""}>
      <div className="">
        <Box
          top={mobileMenuIsOpen ? "10" : "0"}
          bottom={mobileMenuIsOpen ? "" : "0"}
          m="auto"
          position={mobileMenuIsOpen ? "fixed" : "absolute"}
          className="absolute z-50 right-6  h-4"
        >
          <HamburgerMenu
            isOpen={mobileMenuIsOpen}
            color={"#20364A"}
            menuClicked={handleChangeMenuOpenedClicked}
            width={20}
            height={15}
            strokeWidth={2}
            animationDuration={0.23}
          />
        </Box>
      </div>
      <MobileMenuOverlay open={mobileMenuIsOpen} />
    </div>
  );
};
