import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";

export interface FooterSocialsProps {
  className?: string;
}

export const FooterSocials = (props: FooterSocialsProps) => {
  const { className = "" } = props;
  return (
    <div className={`${className} flex space-x-4 text-xl`}>
      <div className="opacity-50 transform hover:opacity-80 transition-all duration-200">
        <a
          href="https://www.facebook.com/VirgovicJaroslav"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white "
        >
          <FaFacebookF />
        </a>
      </div>
      <div className="opacity-50 transform hover:opacity-80 transition-all duration-200">
        <a
          href="https://www.linkedin.com/in/jaroslav-virgovi%C4%8D-37b219188/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white "
        >
          <FaLinkedinIn />
        </a>
      </div>
      <div className="opacity-50 transform hover:opacity-80 transition-all duration-200">
        <a
          href="https://www.instagram.com/jaroslav_virgovic/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white "
        >
          <FaInstagram />
        </a>
      </div>
    </div>
  );
};
