import React from "react";

export interface FooterBottomProps {
  className?: string;
}

export const FooterBottom = (props: FooterBottomProps) => {
  const { className = "" } = props;
  return (
    <div className={`${className} bg-white py-2 flex justify-center text-terciary`}>
      Všetky práva vyhradené jaroslavvirgovic.sk
    </div>
  );
};
