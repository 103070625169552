import React from "react";
import { FooterBottom } from "./components/FooterBottom";
import { FooterTop } from "./components/FooterTop";

export interface FooterProps {
  className?: string;
}

export const Footer = (props: FooterProps) => {
  return (
    <footer className={props.className + ` mt-0`}>
      <FooterTop />
      <FooterBottom />
    </footer>
  );
};
