import { Link } from "gatsby";
import React from "react";
import { Route } from "../../../../lib/routes";

export interface FooterLinkProps extends Route {
  className?: string;
  external?: boolean;
}

const Label = ({ label = "" }) => (
  <div className="text-white opacity-50 hover:opacity-90 transition-all duration-200">{label}</div>
);

export const FooterLink = (props: FooterLinkProps) => {
  const { className = "", label, to, external } = props;
  if (external) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer">
        <Label label={label} />
      </a>
    );
  }
  return (
    <Link to={to} className={`${className}`}>
      <Label label={label} />
    </Link>
  );
};
