import { graphql, useStaticQuery } from "gatsby";

export interface ContactInfo {
  phone: string;
  email: string;
  address: string;
}

export const useContactInfo = (): ContactInfo => {
  const { strapiContact } = useStaticQuery(graphql`
    query useContactInfo {
      strapiContact {
        phone
        email
        address
      }
    }
  `);

  return strapiContact;
};
