export interface Route {
	label: string;
	to: string;
	showInMenu?: boolean;
}

const asRoute = (route: Route): Route => (route)

export const routes = {
	home: asRoute({
		label: "Domov",
		to: "/"
	}),
	howIWork: asRoute({
		label: "Ako pracujem?",
		to: "/ako-pracujem/"
	}),
	realEstateServices: asRoute({
		label: "Realitné služby",
		to: "/realitne-sluzby/"
	}),
	realEstateLocations: asRoute({
		label: "Realitné lokality",
		to: "/realitne-lokality/"
	}),
	blog: asRoute({
		label: "Realitný magazín",
		to: "/realitny-magazin/"
	}),
	contact: asRoute({
		label: "Kontakt",
		to: "/kontakt/"
	}),
	iWantToBecomeAnAgent: asRoute({
		label: "Pridaj sa ku mne do tímu",
		to: "/chcem-sa-stat-maklerom/",
		showInMenu: false
	}),
	iWantToBecomeATipper: asRoute({
		label: "Chcem sa stať tipérom",
		to: "/chcem-sa-stat-tiperom/",
		showInMenu: false
	}),
	instagramLinks: asRoute({
		label: "Instagramové odkazy",
		to: "/instagramove-odkazy/",
		showInMenu: false
	})
};

// Generators /////////////////
export const generateLocationRoute = (slug: string) => `/realitne-lokality/${slug}/`

export const generateBlogRoute = (slug: string) => `/realitny-magazin/${slug}/`