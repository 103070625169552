import { graphql, Link, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import React from "react";
import { routes } from "../../../../lib/routes";
import LogoImage from "../assets/logo.svg";

export type LogoLinkProps = React.HTMLAttributes<HTMLElement>;

export const LogoLink: React.FC<LogoLinkProps> = (props: LogoLinkProps) => {
  const { file } = useStaticQuery(graphql`
    query Logo {
      file(absolutePath: {}, relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Link to={routes.home.to} className={props.className + " flex"}>
      <img src={LogoImage} alt="" />
    </Link>
  );
};
