import { Link } from "gatsby";
import React from "react";
import { routes } from "../../../lib/routes";
import { Container } from "../Container/Container";
import { LogoLink } from "./components/LogoLink";
import { MobileMenu } from "./components/MobileMenu";
import { TopHeader } from "./components/TopHeader";

const renderNavItems = () =>
  Object.values(routes)
    .filter((route) => route.showInMenu !== false)
    .map((route) => (
      <Link
        key={route.to}
        activeClassName={"text-primary"}
        className={
          "hover:text-primary transition duration-200 font-medium text-lg"
        }
        to={route.to}
      >
        {route.label}
      </Link>
    ));

export const Header = (props) => {
  return (
    <header className={"flex flex-col relative"}>
      <TopHeader className="hidden lg:flex" />
      <nav className="bg-white py-4 shadow-lg">
        <Container className="flex justify-between items-center">
          <div className="items-center flex-nowrap">
            <LogoLink />
          </div>
          <div className="hidden nav-items xl:flex justify-end space-x-8 items-center font-medium ">
            {renderNavItems()}
          </div>
          <div className=" xl:hidden h-full w-10">
            <MobileMenu />
          </div>
        </Container>
      </nav>
    </header>
  );
};
