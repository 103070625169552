import React from "react";
import { useContactInfo } from "../../../../lib/hooks/useContactInfo";
import { routes } from "../../../../lib/routes";
import { Container } from "../../Container/Container";
import SvgLogoWhite from "../assets/LogoWhite";
import { FooterLink } from "./FooterLink";
import { FooterSectionHeader } from "./FooterSectionHeader";
import { FooterSocials } from "./FooterSocials";

export interface FooterTopProps {
  className?: string;
}

export const FooterTop = (props: FooterTopProps) => {
  const { className = "" } = props;
  const { phone, email, address } = useContactInfo();

  return (
    <div className={`${className} bg-secondary py-16`}>
      <Container className="grid gap-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
        <section className="footer-logo ">
          <SvgLogoWhite />
        </section>
        <section className="importantLinks ">
          <FooterSectionHeader>Dôležité linky</FooterSectionHeader>
          <FooterLink label={"Pravidlá ochrany GDPR"} to="/gdpr.pdf" external />
          <FooterLink
            label="Katastrálny portál"
            external
            to="https://www.katasterportal.sk/kapor/"
          />
          <FooterLink
            label="Mapka GKU"
            external
            to="https://zbgis.skgeodesy.sk/mkzbgis/sk/kataster?bm=zbgis&z=8&c=19.530000,48.800000#"
          />
        </section>
        <section className="services ">
          <FooterSectionHeader>Služby</FooterSectionHeader>
          <FooterLink
            label={"Predaj nehnuteľnosti"}
            to={`${routes.realEstateServices.to}#predaj`}
          />
          <FooterLink
            label={"Kúpa nehnuteľnosti"}
            to={`${routes.realEstateServices.to}#kupa`}
          />
          <FooterLink
            label={"Ocenenie nehnuteľnosti"}
            to={`${routes.realEstateServices.to}#ocenenie`}
          />
        </section>
        <section className="contact ">
          <FooterSectionHeader>Kontakt</FooterSectionHeader>
          <FooterLink label={phone} to={`tel:${phone}`} external />
          <FooterLink
            label={"jaroslav.virgovic@brickreality.sk"}
            to={`mailto:jaroslav.virgovic@brickreality.sk`}
            external
          />
          <div className="socials mt-4">
            <FooterSocials />
          </div>
        </section>
      </Container>
    </div>
  );
};
